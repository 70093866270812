@charset "UTF-8";
/* CSS Document */

/*@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url('../fonts/proxima/regular/ProximaNova-Reg-webfont.eot');
    src: url('../fonts/proxima/regular/ProximaNova-Reg-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/regular/ProximaNova-Reg-webfont.woff') format('woff'),
         url('../fonts/proxima/regular/ProximaNova-Reg-webfont.ttf') format('truetype'),
         url('../fonts/proxima/regular/ProximaNova-Reg-webfont.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'proxima_nova_rgbold';
    src: url('../fonts/proxima/bold/ProximaNova-Bold-webfont.eot');
    src: url('../fonts/proxima/bold/ProximaNova-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/bold/ProximaNova-Bold-webfont.woff') format('woff'),
         url('../fonts/proxima/bold/ProximaNova-Bold-webfont.ttf') format('truetype'),
         url('../fonts/proxima/bold/ProximaNova-Bold-webfont.svg#proxima_nova_rgbold') format('svg');
    font-weight: normal;
    font-style: normal;

}*/
html {
    background: #fff;
    font-style: normal;
    font-weight: normal
}
body {
    line-height: 1;
    font-size: 100%;
    color: #272727;
/*    font-family: 'proxima_nova_rgregular', Arial, Sans-serif;
    font-weight: normal;
    font-style: normal;*/
    background: #fff;
    font-family: halyard-display, sans-serif;
    font-weight: 400;
    font-style: normal;
}
html, body {
    height: 100%;
    min-height: 100%
}
body.page-error {
    background-color: #eeeeef
}
.login {
    min-height: 100%
}
.loginbox a:not(.button) {color: #9A3EFF}

hr {
    border: none;
    background: none;
    height: 0;
    margin: 40px 0
}
hr:before {
    display: block;
    width: 100%;
    height: 1px;
    background: #E4E3E3;
    content: ""
}
.ease {
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}
.ease.ease-all * {
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}
h1 {
    line-height: 1.2em
}
strong, h1, h2, h3, h4, h5, h6, .bodycopy a, .nonmindshare-sash, .subheading, #useraccount a span, span.disabled, .material .input-field label, .material .btn-flat, .material .select-dropdown li.optgroup, .bodycopy input[type="submit"].anchor-style, .moredetails, .mobile-toggle-rows td:before {
    font-family: halyard-display, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
}
.search h2, .search-bulk, .search-view, .pageheading h2, .pageheading h1, .infopanel .bulk span, .infopanel.iconblock a, .score a.disabled strong {
    font-family: halyard-display, sans-serif;
}
.bodycopy h2 a {
    font-family: halyard-display, sans-serif;
    font-weight: normal;
    font-style: normal;
    border: none;
    text-transform: none;
    font-size: 0.7em;
}
.hidden {
    display: none;
    visibility: hidden
}
.uppercase {text-transform: uppercase}
h2, h3 {
    font-size: 2em;
    text-transform: none;
    margin: 20px 0;
    color: #2D2D2D
}
.colour-primary h2, .colour-primary h3 {
    color: #fff
}
.colour-primary.morecasestudies h2 {
    color: #000
}
.bodycopy p, .bodycopy {
    line-height: 1.5em;
    margin: 0 0 27px 0;
    color: #313131;
    font-size: 14px
}
.colour-primary .bodycopy {
    background: none
}
.bodycopy img {
    max-width: 100%
}
.bodycopy {
    padding: 40px 0;
    background: #fff
}
.justified {
    text-align: justify
}
.twocol {
    width: 50%;
    float: left;
    padding-left: 15px
}
.twocol.inline-cs-image {
    height: 250px;
    overflow: hidden;
    margin: 20px 0
}
.twocol img {
    width: 455px;
    transform: translateY(-10%)
}
.twocol-parent {display: flex; margin: 0 -15px; flex-wrap: wrap}
.twocol-parent > div {width: 50%}
.twocol-parent > div {padding: 15px}
.threecol {
    width: 33.333%;
    padding-left: 15px
}
.threecol:first-child {
    padding-left: 0
}
.bodycopy a {
    text-decoration: none;
    color: #9a3eff;
    border-bottom: 1px solid transparent;
}
.bodycopy a:hover {
    border-color: #7f0bff; color: #7f0bff
}
    .button, input[type="submit"], .bodycopy a.button {
        position: relative;
        top: 0;
        -ms-transition: background 200ms ease;
        -webkit-transition: background 200ms ease;
        transition: background 200ms ease;
        color: #fec8ff;
        padding: 11px 25px 11px 25px;
        background: #9a3eff;
        /* box-shadow: inset 0 -4px 0 #751d59;*/
        /*  text-transform: uppercase;
    font-family: 'proxima_nova_rgregular', Arial, Sans-serif;*/
        font-weight: normal;
        font-style: normal;
        border: none;
        border-radius: 0;
        text-align: center;
        border: none;
        border-radius: 0.1875rem;
    }
.bodycopy a.button, .button-block .button, .button-block input[type="submit"] {margin-bottom: 27px; display: inline-block}
.button.no3d, .bodycopy .button.no3d, input[type="submit"].no3d, input[type="submit"].blue.no3d, .bodycopy a.button.blue.no3d {
    padding: 11px 25px 11px 25px;
    box-shadow: none
}
.button.no3d:active, .bodycopy .button.no3d:active, input[type="submit"].no3d:active {
    padding: 11px 25px 11px 25px;
    top: 0
}
input[type="submit"][disabled], input[type="submit"][disabled]:active, input[type="submit"][disabled]:hover {
    cursor: not-allowed;
    background: #C5C5C5;
    color: #F1F1F1
}
.button.blue, input[type="submit"].blue, .bodycopy a.button.blue {
    background: #9a3eff;
   /* box-shadow: inset 0 -4px 0 #0885B5*/
}
.button.purple, input[type="submit"].purple, .bodycopy a.button.purple {
    background: #9a3eff
}
    .button.purple:hover, input[type="submit"].purple:hover, .bodycopy a.button.purple:hover {
        background: #9a3eff
    }
.button:hover, input[type="submit"]:hover, .bodycopy a.button:hover {
    background: #7f0bff
}
.button.blue:hover, input[type="submit"].blue:hover, .bodycopy a.button.blue:hover {
    background: #10B3F3
}
.button:active, input[type="submit"]:active, .bodycopy a.button:active {
    box-shadow: none;
    padding: 11px 25px 11px 25px;
    top: 4px
}

.mindshare img {
    max-width: 181px;
}

.header-bar {
    z-index: 2;
    position: relative
}
.nonmindshare-sash {
    width: 180px;
    height: 180px;
    color: #9a3eff;
    text-align: center;
    padding-top: 130px;
    position: absolute;
    display: block;
    transform: rotate(45deg);
    right: -90px;
    top: -90px;
    text-transform: uppercase;
    z-index: 1;
    line-height: 1;
    z-index: 999
}
    .nonmindshare-sash .sash-highlight {
        color: #9a3eff
    }
.morecasestudies .nonmindshare-sash {
    padding-top: 125px
}
.morecasestudies.homeversion {
    margin-bottom: 0px
}
.hero {
    height: 550px;
    background-color: #dedee0;
    position: relative;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    overflow: hidden;
	z-index: 0
}
.hero.slideshow {
    overflow: visible
}
.hero.slideshow .bx-has-pager {
    position: absolute;
    bottom: -51px;
    text-align: center;
    width: 100%;
    display: block;
    border-bottom: 1px #E4E3E3 solid;
    padding: 15px 0
}
.hero.slideshow .bx-has-pager .bx-pager-item {
    display: inline-block
}
.hero.slideshow .bx-has-pager .bx-pager-item a {
    border-radius: 50%;
    background: #fff;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    display: inline-block;
    text-indent: -9999px;
    outline: none;
    border: 2px #000 solid
}
.hero.slideshow .bx-has-pager .bx-pager-item a.active {
    background: #000;
}
.hero:before, .hero.slideshow .bxslider>li .featuredimage:after {
    content: "";
    background: url("../images/gridline.png") repeat left top;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}
.video-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    display: none;
    visibility: hidden
}

.video-overlay.open {
    display: block;
    visibility: visible
}
.video-overlay iframe {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.video-overlay .inner-video-overlay {
    position: fixed;
    width: 90%;
    max-width: 940px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.inner-video-overlay:before {
    padding-top: 56.25%;
    content: "";
    display: block;
}
.video-overlay .button {
    position: absolute;
    bottom: -43px;
    right: 0;
    top: initial
}
.video-overlay .button:active {
    top: initial
}
.infopanel {
    padding: 20px 0 15px 0;
}
.infopanel.iconblock {
    padding-top: 30px;
	padding-bottom: 30px;
	display: flex;
	justify-content: space-between
}
.infopanel.iconblock > * {display: inline-flex}
.infopanel.iconblock * {vertical-align: middle; border: none}
.icons {font-size: 14px}
.icons a {display: block; position: relative; border: none}
.infopanel.iconblock a:not(.button) {color: #231744; transition: all 0.2s ease}

.infopanel.iconblock .score a.disabled {cursor: default}
.infopanel.iconblock .score a.disabled:hover {color:#231744}


.infopanel.iconblock a:not(.button):hover,
.infopanel.iconblock .dropdown.open > a:not(.button),
.infopanel.iconblock .score a.opened {color: #7f0bff}

.infopanel.iconblock .dropdown-item a.disabled, .infopanel.iconblock .dropdown-item a.disabled:hover {color: #d4d4d4;cursor: not-allowed; background-color: transparent;}


.icons svg {
    width: 50px;
    height: 40px;
    color: #9a3eff;
    transition: all 0.2s ease
}
.icons svg + svg {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0
}
.icons div:not(.dropdown) a:focus svg, .icons div.dropdown.open a svg{opacity: 0}
.icons div:not(.dropdown) a:focus svg + svg,  .icons div.dropdown.open a svg + svg {opacity: 1}
.icons > div {margin-right: 30px; position: relative; display: inline-block}

.icons .dropdown > a:after, .back-button:before {
	content: "";
    height: 7px;
    width: 7px;
    border-style: solid;
    border-color: #231844;
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg);
    display: inline-block;
    margin-left: 7px;
    position: relative;
    top: -2px;
}
.back-button:before {transform: rotate(135deg)}
a.back-button {border: none; position: relative;
    top: 12px;}
.icons .dropdown .dropdown-item {
	position: absolute;
    transition: all 600ms cubic-bezier(1, 0, 0, 1);
    opacity: 0;
    max-height: 0;
    transform: translateY(20px);
    top: 150%;
    width: 240px;
    right: -14px;
    z-index: 999;
    visibility: hidden
}
.icons .dropdown.open .dropdown-item {
transform: translateY(0px);
    visibility: visible;
    max-height: 350px;
    opacity: 1
}
.icons .dropdown .dropdown-item.from-left {right: auto; left: 0px}



.dropdown-item ul {
	background: #ffffff;
    padding: 10px 0;
    box-shadow: 0 2px 5px 1px rgba(0,0,0,0.2)
}
.dropdown-item ul:before, .dropdown-item ul:after, #useraccount ul:before, #useraccount ul:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 15px 12px;
    border-color: transparent transparent #fff transparent;
    content: "";
    top: -9px;
    right: 13px;
    position: absolute;
}
.dropdown-item ul:after, #useraccount ul:after {
	 border-width: 0 14px 17px 14px;
	 top: -11px;
    right: 11px;
	border-color: transparent transparent #f3f3f3 transparent;
	z-index: -1
}
.dropdown-item.from-left ul:before {right: auto; left: 14px}
.dropdown-item.from-left ul:after {right: auto; left: 12px}
.score span {
    width: 37px;
    height: 37px;
    text-align: center;
    color: #fff;
    background: #9a3eff;
    display: inline-block;
    border-radius: 50%;
    margin: 0 10px 0 0;
    vertical-align: middle;
    line-height: 37px;
    font-size: 16px
}
.score a span {margin: 0 10px 0 30px}
.author>div>div {
    width: 100%;
    padding: 20px 20px 20px 20px;
    color: #fff
}
.hero.slideshow .author>div>div.authorinfo {
    padding: 20px 17px 30px 17px
}
.morecasestudies .casestudieswrap .author>div>div.authorinfo {
    padding: 20px
}
.author .imageblock {
    z-index: 9;
    background: #7f0bff;
    width: 34%;
    height: 100px;
    float: left;
    padding: 10px;
    position: relative
}
.morecasestudies.homeversion .casestudieswrap .box .author .imageblock, .morecasestudies.homeversion .casestudieswrap .box .author .headings:before {
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}
.morecasestudies.homeversion .casestudieswrap .box:hover .author .imageblock, .morecasestudies.homeversion .casestudieswrap .box:hover .author .headings:before {
    background: #7f0bff;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}
.morecasestudies.homeversion .author .headings:before {
    position: absolute;
    background: #751d59;
    width: 83px;
    height: 100%;
    content: "";
    left: 0;
    top: 0
}
.author .imageblock.homeversion {
    background: none
}
.author .imageblock.homeversion figure, .morecasestudies.homeversion .casestudieswrap .box figure {
    width: 77%;
    height: 77%;
    position: absolute;
    left: 17px;
    top: 15px;
    overflow: hidden;
    margin: 0
}
.morecasestudies.homeversion .casestudieswrap .box figure {
    width: 63px;
    height: 67px;
    left: 9px
}
.author .imageblock.homeversion figure img, .morecasestudies.homeversion .casestudieswrap .box figure img {
    position: absolute;
    height: 100%;
    width: auto;
    max-width: none;
    left: 50%;
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -ms-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    top: 0
}
.morecasestudies.homeversion .casestudieswrap .box figure.icon img {
    -webkit-filter: grayscale(0%);
    filter: none
}
.author>div>div:first-child {
    width: 100%;
    padding: 0;
    padding-top:6px
}

    .author {
    width: 100%;
    max-width: 310px;
    bottom: 0;
    border-top: 4px #9a3eff solid;
    -ms-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    z-index: 9;
    -webkit-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}
.hero .author {width: 32%;}
.hero.slideshow .bx-controls-direction {
    display: none
}
.hero.slideshow .container {
    height: 550px;
    position: relative
}
.hero.slideshow .author:nth-child(1){
	left: 0
}
.hero.slideshow .author:nth-child(2) {
    left: calc(50% - 16%)
}
.hero.slideshow .author:nth-child(3) {
    left: auto;
    right: 0
}
.author .headings {
    width: 66%;
    padding: 0 15px 0px 15px
}
.hero .author h1 {
    margin: 15px 0 0 0;
    width: 100%;
    float: left;
    line-height: 1.2em;
}
.hero .author.active h1 {
  color:#FEC8FF;
}
.hero.slideshow .featuredimage {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    -webkit-transition: all 0.6s ease-in-out;
    -ms-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out
}
.hero.slideshow .featuredimage img {
    position: absolute;
    display: block;
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%)
}
.hero.slideshow .featuredimage {
    opacity: 0
}
.hero.slideshow .featuredimage.active {
    opacity: 1
}
.author h1, .author .title, .author .headings h4 {
    margin: 15px 0 15px 0;
    color: #FEC8FF;
    font-size: 22px;
    line-height: 1em;
    text-transform:none;
}
.author:not(.active) h1 {
color:#FEC8FF;
}
.hero.slideshow .author {
    bottom: auto;
    top: 100%;
    z-index: 100;
    -webkit-transform: translateY(-120px);
    -ms-transform: translateY(-120px);
    -o-transform: translateY(-120px);
    transform: translateY(-120px);
    transition: all 600ms cubic-bezier(0.86, 0.12, 0.03, 0.95);
    -ms-transition: all 600ms ease;
    background: #f7f6f7;
}
    .hero.slideshow .author:hover, .hero.slideshow .author.active {
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
        border-top-color: #9a3eff;
        background: #9a3eff;
    }
.hero.slideshow .author:hover figure img, .hero.slideshow .author.active figure img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%)
}
.author .headings em {
    margin: 10px 0 0 0;
    position: relative;
    top: 5px;
    display: inline-block;
    padding: 4px 10px;
    font-size: 10px;
    font-style: normal;
    background: #000;
    color:#fff;
    border-radius:25px;
    text-transform: uppercase;
    line-height: 1
}
.author .authorinfo {
    line-height: 1.2em
}
.author .authorinfo p {
    margin: -10px 0 20px 0;
    font-size: 14px;
    line-height: 1.4em
}
.author .authorinfo li {
    padding-top: 7px;
    text-transform: uppercase;
    font-size: 0.929em
}
.author .authorinfo li:first-child {
    padding-top: 0px
}
    .author .authorinfo a.button {
        padding: 6px 20px 6px 20px;
        display: inline-block;
        background: #fec8ff;
        color: #9a3eff;
    }
        .author .authorinfo a.button:hover {
 
            background: #feafff;
        }
.views>div {
    width: 100%;
    color: #FEC8FF;
    display: block;
    text-align: center
}
.views>div strong {
    display: block;
    font-size: 1.429em
}
.views>div:before {
    content: "";
    background-position: -300px -15px;
    width: 40px;
    height: 24px;
    display: inline-block
}
.container.infopanel.case-study-page:before {
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    left: 83px;
    border-style: solid;
    border-width: 20px 12.5px 0 12.5px;
    border-color: #9a3eff transparent transparent transparent;
    content: "";
    -moz-transform: scale(0.9999);
    opacity: 0.9
}
#advancedsearch .container.infopanel:before {
    display: none
}
.author.cotm:after {
    display: none
}
.slideshow {
    margin: 20px 0
}
section.colour-primary.morecasestudies {
    color: #fff;
    background-color: #f7f6f7;
    padding: 0 0 20px 0
}
.morecasestudies h3 {
    margin: 25px 0 10px 0;
    color: #000
}
.casestudieswrap>div {
    width: calc(35% - 33px);
    height: 290px;
    margin-left: 26px
}
.casestudieswrap.cotm-page {
    margin-top: -26px;
    margin-bottom: 50px;
	display: flex;
    flex-wrap: wrap;
}
.casestudieswrap.cotm-page .box .imgwrap {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%)
}
.casestudieswrap.cotm-page .box {
    height: 290px
}
.casestudieswrap.cotm-page>div {
	height: auto;
    min-height: 370px;
    margin: 26px 26px 0 0
}
.casestudieswrap.cotm-page>div:nth-child(3n+0) {
    margin-right: 0
}
.casestudieswrap .box {
    background: #ededed
}
.morecasestudies.homeversion .casestudieswrap>div {
    width: calc(50% - 13px);
    height: 214px
}
.morecasestudies.homeversion .casestudieswrap>div div.splitbox {
    height: 216px;
    width: calc(50% - 13px)
}
.casestudieswrap>div.slider {
    width: 65%;
    height: 610px;
    padding-right: 0;
    background: #ededed;
    margin: 0;
    position: relative
}
.morecasestudies.homeversion .casestudieswrap>div.slider {
    width: calc(50% - 13px)
}
.casestudieswrap>div:last-child {
    margin-top: 27px
}
.casestudieswrap .box {
    height: 100%;
    overflow: hidden
}
.casestudieswrap .box .title {
    padding: 13px 15px;
    font-size: 21px;
    margin: 0;
    line-height: 1
}
    .casestudieswrap .box.small .author {
        width: 100%;
        background: #9a3eff;
        -ms-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        color:#fff;
    }
    .casestudieswrap .box.small:hover .author {
        background: #7f0bff;
    }
.casestudieswrap .slide .author .views:after {
    display: none
}
.author .views {
    top: auto;
    bottom: 0;
    left: 0;
    line-height: 1
}
.casestudieswrap .box .author {
    border-top-color: #9a3eff
}
.casestudieswrap .box a {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-indent: -9999px;
    outline: none;
    border: none;
    z-index: 100
}
.casestudieswrap .box a:hover {
    border: none
}
.casestudieswrap .box .imgwrap img, .resultblock .figure img {
    -ms-transform-origin: center center;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -ms-transition: all 3s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition: all 3s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 3s cubic-bezier(0.215, 0.61, 0.355, 1);
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    backface-visibility: hidden
}
.casestudieswrap .box .imgwrap img:after, .resultblock .figure img:after, .slideshow.relative li img:after, .casestudieswrap .box .imgwrap img:before, .resultblock .figure img:before, .slideshow.relative li img:before, .resultblock .figure img:before, .resultblock .figure img:after {
    content: "";
    background: #eaeaea url("../images/mindshare-placeholder.png") no-repeat center center;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 618px;
    height: 610px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 100
}
.slideshow.relative li img:after, .resultblock .figure img:after {
    width: 940px;
    height: 520px
}
.resultblock .figure img:after {
    background-size: 200px auto
}
.casestudieswrap .box img {
    height: 100%;
    width: auto;
    max-width: none
}
.morecasestudies.homeversion .casestudieswrap .box.small img.overlayicon {
    height: auto;
    width: auto;
    -ms-transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
}
.morecasestudies.homeversion .casestudieswrap .box.small:hover img.overlayicon {
    -ms-transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
}
.casestudieswrap .box .imgwrap {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    height: 100%;
    width: 100%;
    -ms-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease
}
.morecasestudies.homeversion .casestudieswrap .box.small .imgwrap {
    height: 100%;
    width: 100%
}
.morecasestudies.homeversion .casestudieswrap .box.small .author {
    height: 72px
}
.casestudieswrap .box:hover .imgwrap {
    -webkit-filter: grayscale(0%) blur(0px);
    filter: grayscale(0%) blur(0px);
    -ms-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease
}
.reset-link {
    float: right;
    padding-top: 16px;
    text-transform: none
}
.resultblock .figure img {
    height: 100%;
    width: auto
}
.casestudieswrap .box:hover>.imgwrap img, .resultblock .figure:hover .imgwrap img, .casestudieswrap .slider:hover>.imgwrap img {
    -ms-transform: translate(-50%, -50%) scale(1.2);
    -webkit-transform: translate(-50%, -50%) scale(1.2);
    transform: translate(-50%, -50%) scale(1.2)
}
.casestudieswrap .box .author .title {
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #FEC8FF;
}
.search h2, .pageheading h2, .pageheading h1 {
    font-size: 1.429em;
    color: #fff;
    margin: 0 20px 0 0;
    line-height: 85px
}
.search input[type="text"] {
    margin: 22px 0;
    height: 40px;
    width: 720px;
	max-width: 100%;
    border: none;
    padding: 0 10px;
    font-size: 1.429em;
	border-radius: 0
}
.subheading {
    font-size: 1.286em;
    margin: 0 0 27px 0;
    color: #464646
}
.search label:hover, .search select:hover, .search input[type="submit"]:hover {
    cursor: pointer
}
input[type="submit"]:focus {
    outline: none
}
.search select {
    transition: box-shadow 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    border-radius: 0;
    width: 100%;
    display: block;
    margin-bottom: 15px;
    border: none;
    background: #fff;
    padding: 8px 10px;
    -webkit-appearance: none;
    font-size: 15px
}
.search select:hover {
    border-bottom-color: #000;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
.search select {
    background: #fff url("../images/dropper.png") no-repeat 97% 11px;
    background-size: 10px auto;
    border-bottom: 1px solid #C1C1C1;
    -webkit-transition: all 0.3s ease
}
}
.search .checkbox input {
    float: right
}
.search .checkbox label {
    background: #000;
    display: block;
    padding: 8px 10px;
    color: #fff;
    text-align: left;
    overflow: auto;
    text-transform: none;
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1)
}
.search .checkbox label:hover {
    background: #000
}
.search .container .form:last-child {
    border-top: 1px solid #fff;
    margin: 25px 0 0 0;
    padding: 40px 0 25px 0
}
.search .container>input[type="submit"] {
    top: -2px;
    height: 44px
}
.search .container>input[type="submit"]:active {
    top: -2px;
    height: 40px
}
.search .container .form input[type="submit"] {
    width: 100%;
    box-shadow: none;
    padding: 11px 25px 11px 25px
}
#advancedtoggle {
    color: #fff;
    margin-left: 10px
}
#advancedtoggle:before {
    content: "Open filters"
}
#advancedtoggle.open:before {
    content: "Close filters"
}
#advancedsearch {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0
}
#advancedsearch.open {
    max-height: 460px;
    transform: scale(1);
    opacity: 1;
    border-bottom: 1px #fff solid
}
#advancedsearch>.container {
    transform: scale(0.8);
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
}
#advancedsearch.open>.container {
    transform: scale(1)
}
.resultblock>div {
    width: 67%; position: relative; margin: 0 0 20px 0
}
.searchresults.list .resultblock>div:first-child {
    max-width: calc(33% - 26px);
    margin-right: 26px;
}
.resultblock .figure {
    height: 221px;
    width: 100%;
    position: relative;
    margin: 0;
    overflow: hidden
}
.resultblock .figure a {
    border: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-indent: -9999px
}
.resultblock .figure a:hover {
    border: none
}
.resultblock .views {
    bottom: 0;
    width: 80px;
    padding: 20px
}
.resultblock {
    margin-bottom: 30px;
    line-height: 1
}
.searchresults .resultblock:last-child {
    margin-bottom: 0
}
.resultblock .views>div:before {
    left: 29px;
    top: 25px
}
.resultblock .infopanel {
    padding: 20px;
}
.resultblock .details {
    padding: 0 0 0 20px;
    min-height: 140px
}
.resultblock .details h3 {
    margin: 0
}
.resultblock .details ul {
    margin-top: 10px;
    line-height: 1.5em
}
.pagination {
    padding: 15px 0 20px 0
}
.pagination a, span.disabled {
    padding: 10px;
    margin-right: 20px
}
span.disabled {
    color: #DEDEE0;
    border-bottom: 1px solid #DEDEE0
}
.bx-prev, .bx-next {
    float: left;
    width: 0;
    height: 0;
    border-style: solid;
    text-indent: -9999px;
    outline: none;
    opacity: 0.9;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block
}
.container.bodycopy.home {
    padding: 108px 0 64px 0
}
.hero.slideshow {
    background: none;
    margin: 0
}
.bxslider.homeheader {
    height: 550px;
    overflow: hidden;
    transition-timing-function: ease
}
.hero.slideshow>ul>li, .hero.slideshow .bxslider>li {
    background-color: #130A29;
    overflow: hidden
}
.hero.slideshow>ul>li, .bxslider>li {
    height: 550px !important;
    position: relative
}
.hero.slideshow .author:not(.active) h1{
    color: #000;
}
.casestudieswrap .bxslider>li {
    height: 610px !important
}
.bx-next.disabled, bx-prev.disabled {
    display: none
}
.bx-prev:hover, .bx-next:hover {
    opacity: 1
}
.bx-prev {
    margin-left: 20px;
    border-width: 17.5px 30.3px 17.5px 0 !important;
    border-color: transparent #dedee0 transparent transparent !important;
    left: 10px
}
.bx-next {
    right: 10px;
    margin-right: 20px;
    border-width: 17.5px 0 17.5px 30.3px !important;
    border-color: transparent transparent transparent #dedee0 !important
}
.casestudieswrap .slider.left .slide {
    height: 610px
}
.morecasestudies.homeversion .casestudieswrap .slider.left .slide, .morecasestudies.homeversion .casestudieswrap>div.slider {
    height: 458px
}
.playbutton {
    border-radius: 50%;
    background: rgba(0,0,0,0.3);
    border: 1px rgba(255,255,255,0.8) solid;
    width: 140px;
    height: 140px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    text-indent: -9999px;
    outline: none;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease
}
.playbutton:after {
    content: "";
    position: absolute;
    top: 55px;
    left: 55px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 17px 0 17px 30px;
    border-color: transparent transparent transparent #fff
}
.playbutton:hover {
    background: rgba(15, 159, 217,0.8)
}
.footernews {display: flex; flex-direction: row; flex-wrap: wrap; margin-left: -15px; margin-right: -15px}

    .footernews a {
        width: calc(25% - 30px);
        height: 215px;
        padding: 20px;
        margin: 15px;
        position: relative;
        display: block;
        border: 1px #9a3eff solid;
        background: #9a3eff;
        overflow: hidden
    }
.footernews a, .footernews a * {
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}
.squareblock.news a .datetext, .datetext {
    color: #000;
    font-size: 14px;
    text-transform: none;
    display: block
}
.colour-primary h2 a {
    color: #9a3eff;
}

.footernews a:hover {
    border: 1px #7f0bff solid;
    background-color: #7f0bff;
    color:#fff!important;
}
    .footernews a:hover h5 {
        color: #fff !important;
    }
.squareblock.news a:hover {
    background-color: #7f0bff
}
.footernews a:hover h5, .footernews a:hover .datetext, .squareblock.news a:hover h5, .squareblock.news a:hover .datetext {
    color: #FEC8FF
}
   .squareblock.news a:hover .datetext {
        color: #000
    }
.footernews a h5, .squareblock.news a h5 {
    color: #FEC8FF;
    font-size: 21px;
    line-height: 1em;
    margin: 0
}
.verticalcentre {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    padding: 0 20px;
    left: 0
}
.absolutecentre {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    display: block;
    text-align: center;
    height: auto !important;
    border: none;
    background: none;
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-rendering: optimizeLegibility
}
.absolutecentre.blackout {
    width: 124px !important;
    height: 124px !important;
    background: rgba(0,0,0,0.7)
}
.slider .absolutecentre.blackout {
    width: 150px !important;
    height: 150px !important
}
.absolutecentre.blackout .title {
    top: 50%;
    position: relative;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}
.absolutecentre:before, .absolutecentre:after {
    content: "";
    position: absolute;
    height: 1px;
    background: #000;
    top: 0;
    opacity: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0;
    -ms-transition: all 600ms cubic-bezier(0.86, 0.12, 0.03, 0.95);
    -webkit-transition: all 600ms cubic-bezier(0.86, 0.12, 0.03, 0.95);
    transition: all 600ms cubic-bezier(0.86, 0.12, 0.03, 0.95)
}
.gradient:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(221deg, rgba(44, 12, 74, 0) 35%, rgb(154, 62, 255) 95%);
    opacity: 1;
}
.caption-bl .title {
    position:absolute;
    bottom:0px;
    left:0px;
    color:#fff!important;
    max-width:200px;
    z-index:99;
    text-transform:none;
    line-height:1.2em!important;
    font-size:26px!important;
}
.box.blue .absolutecentre:before, .box.blue .absolutecentre:after {
    background: #fff
}
.absolutecentre:after {
    top: auto;
    bottom: 0
}
.box:hover .absolutecentre:before, .box:hover .absolutecentre:after {
    width: 50px;
    opacity: 1
}
.absolutecentre.blackout * {
    color: #fff
}
.footernews a:hover .verticalcentre, .squareblock a:hover .verticalcentre {
    -webkit-transform: translateY(-70%);
    -ms-transform: translateY(-70%);
    -o-transform: translateY(-70%);
    transform: translateY(-70%)
}
.box.yellow {
    background: #f8cd37
}
.box.yellow * {
    color: #000
}
.box.blue {
    background: #1a97d4
}
.box.blue * {
    color: #fff
}
.searchtoggle {
    height: 70px;
    line-height: 70px
}
.searchtoggle .subheading {
    margin: 0;
    font-size: 15px
}
.search-actions a {
    background-color: #fff;
    width: 45px;
    height: 35px;
    display: inline-block;
    position: relative;
    top: 18px;
    margin-left: 7px;
    border: 1px #dedee0 solid;
    -ms-transition: all 400ms cubic-bezier(1, 0, 0, 1);
    -webkit-transition: all 400ms cubic-bezier(1, 0, 0, 1);
    transition: all 400ms cubic-bezier(1, 0, 0, 1)
}
.search-actions a:hover, .search-actions a.active {
    border-color: #C1BEBE
}
.search-actions a:active, .search-actions a.active {
    background-color: #dedee0
}
.search-actions a {
    background-image: url("../images/spritesheet-search.png");
    background-repeat: no-repeat
}
.search-actions .grid {
    background-position: -51px 9px
}
.search-actions .grid.active {
    background-position: -51px -31px
}
.search-actions .list {
    background-position: 10px 9px
}
.search-actions .list.active {
    background-position: 10px -31px
}
.search-actions .search-bulk {
    background-position: -116px 8px
}
.search-actions .search-bulk.active {
    background-position: -116px -32px
}
.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 999
}
.modal:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 1
}
.modal.hidden:before {
    opacity: 0
}
.modal.hidden {
    display: none
}
.modal.hidden {
    display: block
}
.modal .box {
    background: #fff;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 304px;
    position: absolute;
    display: block;
    box-shadow: 0 10px 20px rgba(0,0,0,0.4);
    -webkit-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    transition: all 0.8s ease;
    opacity: 1
}
.modal.hidden .box {
    -webkit-transform: translate(-50%, -80%);
    -ms-transform: translate(-50%, -80%);
    -o-transform: translate(-50%, -80%);
    transform: translate(-50%, -80%);
    opacity: 0
}
.modal .box .inner {
    line-height: 1.4em;
    padding: 20px;
    text-align: center
}
.modal .box .inner h2 {
    line-height: 1.1em;
    margin-top: 0
}
.modal .box strong {
    text-transform: none
}
.modal .box .inner.alert {
    background: #d1e9f0;
    text-align: left;
    position: relative;
    padding-left: 50px
}
.alert p, .alert ul, .info {
    position: relative;
    margin: 0;
    line-height: 1.5em
}
.alert.normal, .alert.error, .alert.ok {
    background: #d1e9f0;
    padding: 15px 15px 15px 35px;
    margin-bottom: 40px
}
.alert.error {
    background: #ffa2a6
}
.alert.ok {
    background: #c7f6bc
}
.alert p:before, .alert ul:before, .info:before {
    position: absolute;
    top: 2px;
    left: -22px;
    content: "!";
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    background: #1caae1;
    border-radius: 50%;
    color: #fff
}
.alert.error p, .alert.error ul {
    color: #fff
}
.alert.error p:before, .alert.error ul:before {
    color: #fff;
    background: #ed1c24
}
.alert.ok p:before, .alert.ok p:before {
    background: #7cda65
}
.info {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 11px;
    line-height: 16px;
    left: 0.75rem;
    display: block;
    z-index: 100
}
.info a {
    color: #FEC8FF;
}
.info a:hover {
    color: #FEC8FF;
    font-weight: bold;
}
.info:hover:before {
    cursor: pointer
}
.info:before {
    content: "?";
    color: #fff;
    background-color: #cdcdcd;
    top: 0;
    left: 0
}
.info:after {content: "";
    position: absolute;
    width: 25px;
    height: 40px;
    bottom: -11px
}

.info .infotext, .search-bulk span, .infopanel .bulk span {
    background: #4f436b;
    position: absolute;
    left: 29px;
    width: 350px;
    top: 50%;
    -webkit-transform: translate(50px, -50%);
    -o-transform: translate(50px, -50%);
    -ms-transform: translate(50px, -50%);
    transform: translate(50px, -50%);
    padding: 15px;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    opacity: 0;
    transition: all 600ms cubic-bezier(1, 0, 0, 1);
    visibility: hidden
}
.search-bulk span, .infopanel .bulk span {
    text-transform: none;
    width: 140px;
    left: 50%;
    top: auto;
    text-align: center;
    bottom: 100%;
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}
.col.m4 .info .infotext {
    width: 270px
}
.info.open .infotext, .search-bulk.download-bubble span, .infopanel .bulk.download-bubble span {
    -webkit-transform: translate(0px, -50%);
    -o-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
    opacity: 1;
    visibility: visible
}
.search-bulk.download-bubble span, .infopanel .bulk.download-bubble span {
    -webkit-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    z-index: 999
}
.info .infotext:before, .search-bulk span:before, .infopanel .bulk span:before {
    content: "";
    width: 0;
    height: 0;
    top: 50%;
    margin-top: -8px;
    left: -12px;
    position: absolute;
    display: block;
    border-style: solid;
    border-width: 8px 13.9px 8px 0;
    border-color: transparent #4f436b transparent transparent
}
.info .infotext:after, .search-bulk span:after, .infopanel .bulk span:after {float: right; content:"x"; font-size: 16px; cursor: pointer}

.search-bulk span:before, .infopanel .bulk span:before {
    top: auto;
    left: 50%;
    margin-left: -6px;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    bottom: -12px
}
.searchresults.grid .resultblock, .squareblock {
    margin: 26px 13px 0 13px;
    width: calc(33% - 26px);
	height: 296px;
    float: left;
    text-rendering: optimizeLegibility;
    position: relative
}
.searchresults.grid .resultblock {
	
}
.squareblock {
    width: calc(25% - 26px);
    height: 215px
}
    .squareblock a {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        background: #9a3eff;
        border: none
    }
.searchresults.grid, .gridcontainer {
	margin-top: -26px;
    margin-left: -13px;
    margin-right: -13px;
}
.searchresults.grid .resultblock:nth-child(3n+0), .squareblock:nth-child(4n+0) {
   /* margin-right: 0*/
}
.searchresults.grid .resultblock .infopanel, .searchresults.grid .resultblock .details ul {
    display: none
}
.searchresults.grid .resultblock>div {
    width: 100%
}
.searchresults.grid .resultblock .details {
    padding: 15px 0 0 0;
    min-height: 40px
}
.searchresults.list .resultblock .details {
    padding: 15px 0 0 20px;
	position: relative
}
aside.morenews {
    width: 27%
}
aside h3 {
    font-size: 18px;
    line-height: 1.1em
}
article.newsarticle {
    padding-right: 33%;
    min-height: 360px
}
article ul {
    margin-bottom: 40px
}
article ul li {
    padding-left: 30px;
    position: relative;
    margin-bottom: 27px
}
article ul li:before {
    position: absolute;
    display: block;
    content: "• ";
    color: #742058;
    left: 0px;
    top: 0;
    font-size: 30px
}
aside ul {
    padding: 20px
}
aside ul li {
    margin-bottom: 20px
}
.bodycopy aside ul li a {
    border: none;
    display: block;
    color: #9a3eff
}
aside ul li:last-child {
    margin-bottom: 0
}
.container .row {
    margin-left: -0.75rem;
    margin-right: -0.75rem
}
.row .col {
    float: left;
    box-sizing: border-box;
    padding: 0 0.75rem
}
.row .col.s12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto
}
.row {
    margin-bottom: 20px
}
.row .col.s6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto
}
.input-field {
    position: relative;
    margin-top: 1rem
}
.material .input-field.withinfo .material .dropdown-content label {
    left: 2.5rem
}
.material .input-field.withinfo label {
    left: 0em
}
.material .input-field.withinfo.nofields label, .material .input-field.nofields label {
    left: 0
}
.material .input-field.withinfo label.active, .material .input-field.withinfo .select-wrapper+label, .material .input-field.withinfo .datelabel {
    left: 0.75rem
}
.material input:not([type]):focus:not([readonly]), .material input[type=text]:focus:not([readonly]), .material input[type=password]:focus:not([readonly]), .material input[type=email]:focus:not([readonly]), .material input[type=url]:focus:not([readonly]), .material input[type=time]:focus:not([readonly]), .material input[type=date]:focus:not([readonly]), .material input[type=datetime-local]:focus:not([readonly]), .material input[type=tel]:focus:not([readonly]), .material input[type=number]:focus:not([readonly]), .material input[type=search]:focus:not([readonly]), .material textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #751d59;
    box-shadow: 0 1px 0 0 #751d59
}
.material input:not([type]):focus:not([readonly])+label, .material input[type=text]:focus:not([readonly])+label, .material input[type=password]:focus:not([readonly])+label, .material input[type=email]:focus:not([readonly])+label, .material input[type=url]:focus:not([readonly])+label, .material input[type=time]:focus:not([readonly])+label, .material input[type=date]:focus:not([readonly])+label, .material input[type=datetime-local]:focus:not([readonly])+label, .material input[type=tel]:focus:not([readonly])+label, .material input[type=number]:focus:not([readonly])+label, .material input[type=search]:focus:not([readonly])+label, .material textarea.materialize-textarea:focus:not([readonly])+label {
    color: #751d59
}
.material input:not([type]).invalid, .material input:not([type]):focus.invalid, .material input[type=text].invalid, .material input[type=text]:focus.invalid, .material input[type=password].invalid, .material input[type=password]:focus.invalid, .material input[type=email].invalid, .material input[type=email]:focus.invalid, .material input[type=url].invalid, .material input[type=url]:focus.invalid, .material input[type=time].invalid, .material input[type=time]:focus.invalid, .material input[type=date].invalid, .material input[type=date]:focus.invalid, .material input[type=datetime-local].invalid, .material input[type=datetime-local]:focus.invalid, .material input[type=tel].invalid, .material input[type=tel]:focus.invalid, .material input[type=number].invalid, .material input[type=number]:focus.invalid, .material input[type=search].invalid, .material input[type=search]:focus.invalid, .material textarea.materialize-textarea.invalid, .material textarea.materialize-textarea:focus.invalid {
    border-bottom: 1px solid #F44336;
    box-shadow: 0 1px 0 0 #F44336
}
.material textarea.materialize-textarea, .hiddendiv {
    padding: 0.6rem 0;
    resize: none;
    min-height: 4em;
    overflow: auto;
    line-height: 1.5em;
    box-sizing: content-box
}
.material textarea.materialize-textarea {
    width: calc(100% - 1.5em);
    overflow: hidden
}
.material input:not([type]), .material input[type=text], .material input[type=password], .material input[type=email], .material input[type=url], .material input[type=time], .material input[type=date], .material input[type=datetime-local], .material input[type=tel], .material input[type=number], .material input[type=search], .material textarea.materialize-textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 1.3rem;
    margin: 0 0 15px 0;
    padding: 0;
    box-shadow: none;
    transition: all .3s;
    position: relative
}
.material input {
    line-height: normal
}
.material .input-field label.active {
    font-size: 0.8rem;
    -webkit-transform: translateY(-140%);
    transform: translateY(-140%)
}
.material .input-field label, .material .select-wrapper + label {
    color: #9a3eff;
    position: absolute;
    top: 0.8rem;
    left: 0.75rem;
    font-size: 1.3rem;
    cursor: text;
    transition: .2s ease-out
}
.material .input-field.withinfo input, .material .input-field.withinfo textarea {
    padding-left: 1.5em
}
.material .input-field.withinfo input {
    padding-left: 1.5em;
    padding-right: 1.5em
}
.material label {
    font-size: 0.8rem;
    color: #9e9e9e;
    text-transform: uppercase
}
.material .input-field label.active, .material .input-field .select-wrapper+label, .material .input-field.withinfo .datelabel {
    font-size: 1rem;
    -webkit-transform: translateY(-140%);
    -ms-transform: translateY(-140%);
    transform: translateY(-140%)
}
.material  .input-field .dropdown-content label {
    display: block;
    position: relative;
    top: 0px !important;
    left: 0px !important;
}

.material .dropdown-content{
    top: 50px !important;
}

.material label{
    cursor: pointer;
}

.material label span{
    margin-left: 30px;
    cursor: pointer;
}

.material [type="checkbox"]+span:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    z-index: 0;
    border: 2px solid #5a5a5a;
    border-radius: 1px;
    margin-top: 2px;
    transition: .2s
}

.material [type="checkbox"]:checked+span:before {
    background: #9a3eff;
    border-color: #9a3eff;
}

.material [type="checkbox"]:checked+span:after {
    position: absolute;
    top: 1px;
    left: 6px;
    width: 16px;
    height: 16px;
    content: "x";
    color: #fff;
}

.material .select-dropdown span {
    font-size: 16px;
    color: #751D59;
    display: block;
    line-height: 20px;
}

.select-wrapper svg.caret {
    color: initial;
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 15px;
    z-index: 0;
}
.subnav li {
    display: inline-block;
    border-bottom: 2px solid #f7f6f7;
}
.subnav li.active {
    border-bottom: 2px solid #751d59;
}
.subnav li.active.error {
    border-bottom: 2px solid #ed1c24;
}
.subnav.numbered li.error a:before {
    background: #ED1C24
}
.subnav li.active.ok {
    border-bottom: 2px solid #7cda65;
}
.subnav.numbered li.ok a:before {
    background: #7CDA65
}
.subnav li a {
    font-size: 15px;
    text-transform: uppercase;
    padding: 25px 15px;
    display: block;
    color: #000;
    position: relative
}
.subnav.numbered li.disabled a, .subnav.numbered li.disabled:hover a {
    color: #C5C5C5;
    cursor: not-allowed
}
.subnav.numbered li a {
    padding: 26px 14px 25px 35px;
    margin-bottom: -2px
}
.subnav.numbered li a:hover {
    color: #000
}
.subnav li.active a, .subnav li.preview a, .subnav li a:hover {
    color: #751d59
}
.subnav li.preview {
    box-shadow: none
}
.subnav li.preview a {
    padding: 25px 41px 25px 35px;
    position: relative
}
.subnav li.preview a:after {
    content: "";
    background-position: -193px -72px;
    background-size: 802px auto;
    width: 40px;
    height: 24px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 20px
}
.material select {
     display: none;
}
.subnav.numbered {
    counter-reset: li a
}
.subnav.numbered li a:before {
    content: counter(li);
    counter-increment: li;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    top: 24px;
    left: 12px;
    background: red;
    text-align: center;
    color: #fff;
    line-height: 18px;
    font-size: 13px
}
.select-wrapper {
    position: relative
}
.select-wrapper span.caret {
    color: initial;
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 15px;
    z-index: 0
}
.material .dropdown-content {
    background-color: #fff;
    margin: 0;
    display: none;
    min-width: 100px;
    max-height: 650px;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    opacity: 0;
    position: absolute;
    z-index: 999;
    will-change: width, height
}
.material .select-dropdown li.disabled, .material .select-dropdown li.disabled>span, .material .select-dropdown li.optgroup {
    color: rgba(0,0,0,0.3);
    background-color: transparent
}
.material .dropdown-content li {
    clear: both;
    color: rgba(0,0,0,0.87);
    cursor: pointer;
    min-height: 40px;
    width: 100%;
    text-align: left;
    text-transform: none
}
.material .dropdown-content li:hover, .material .dropdown-content li.active, .material .dropdown-content li.selected {
    background-color: #eee
}
.material .dropdown-content li.active.selected {
    background-color: #e1e1e1
}
.dropdown-content {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)
}
.material .dropdown-content li>a, .material .dropdown-content li>span {
    font-size: 16px;
    color: #751D59;
    display: block;
    line-height: 20px;
    padding: 10px 16px
}
.material .select-dropdown li.disabled, .material .select-dropdown li.disabled>span, .material .select-dropdown li.optgroup {
    color: rgba(0,0,0,0.3);
    background-color: transparent
}
.material .row .col.m3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto
}
.material .row .col.m4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto
}
.material .picker {
    font-size: 16px;
    text-align: left;
    line-height: 1.2;
    color: #000000;
    position: absolute;
    z-index: 10000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}
.material .picker--opened .picker__holder {
    top: 0;
    background: transparent;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#1E000000,endColorstr=#1E000000)";
    zoom: 1;
    background: rgba(0,0,0,0.32);
    transition: background 0.15s ease-out
}
.material .picker__holder {
    position: fixed;
    transition: background 0.15s ease-out, top 0s 0.15s;
    -webkit-backface-visibility: hidden
}
.material .picker__holder, .material .picker__frame {
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%
}
.material .picker__holder {
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}
.material .picker__frame {
    margin: 0 auto;
    max-width: 300px;
    top: 15%;
    height: 512px;
    position: absolute;
    display: block
}
.material .picker__wrap {
    display: block;
    width: 100%;
    height: 100%
}
.material .picker__box {
    background: #ffffff;
    display: table-cell;
    vertical-align: middle
}
.material .picker__box {
    border-radius: 2px;
    overflow: hidden
}
.material .picker__box {
    display: block;
    border-top-color: #898989;
    border-bottom-width: 0;
    border-radius: 5px;
    box-shadow: 0 12px 36px 16px rgba(0,0,0,0.24)
}
.material .picker__date-display {
    text-align: center;
    background-color: #761959;
    color: #fff;
    padding-bottom: 15px;
    font-weight: 300
}
.material .picker__weekday-display {
    background-color: #530b3e;
    padding: 10px;
    font-weight: 200;
    letter-spacing: .5;
    font-size: 1rem;
    margin-bottom: 15px
}
.material .picker__month-display {
    text-transform: uppercase;
    font-size: 1.8rem
}
.material .picker__day-display {
    font-size: 4.5rem;
    font-weight: 400
}
.material .picker__day-display {
    font-size: 4.5rem;
    font-weight: 400
}
.material .picker__year-display {
    font-size: 1.8rem;
    color: rgba(255,255,255,0.4)
}
.material .picker__day-display {
    font-size: 3em;
    font-weight: 400
}
.material .picker__year-display {
    font-size: 1.8rem;
    color: rgba(255,255,255,0.4)
}
.material .picker__calendar-container {
    padding: 0 1rem
}
.material .picker__header {
    text-align: center;
    position: relative;
    margin-top: .75em
}
.material .picker__month, .material .picker__year {
    display: inline-block;
    margin-left: .25em;
    margin-right: .25em
}
.material .picker__select--year.browser-default {
    display: inline;
    background-color: #FFFFFF;
    width: 25%
}
.material .picker__month, .material .picker__year {
    display: inline-block;
    margin-left: .25em;
    margin-right: .25em
}
.material .picker__select--month, .material .picker__select--year {
    height: 2em;
    padding: 0;
    margin-left: .25em;
    margin-right: .25em
}
.material .picker__select--month {
    display: inline;
    width: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -appearance: none;
    border: none;
    padding: 0;
    margin: 0
}
.material select {
    background-color: rgba(255,255,255,0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: 3rem
}
.material .picker__nav--prev {
    left: -1em;
    padding-right: 1.25em
}
.material .picker__nav--prev, .material .picker__nav--next {
    position: absolute;
    padding: .5em 1.25em;
    width: 1em;
    height: 1em;
    box-sizing: content-box;
    top: -0.25em
}
.material .picker__nav--prev:before, .material .picker__nav--next:before {
    content: " ";
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    border-right: 0.75em solid #676767;
    width: 0;
    height: 0;
    display: block;
    margin: 0 auto
}
.material .picker__nav--next {
    right: -1em;
    padding-left: 1.25em
}
.material .picker__nav--next:before {
    border-right: 0;
    border-left: 0.75em solid #676767
}
.material .picker__table {
    margin-top: 0;
    margin-bottom: .5em
}
.material .picker__table {
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    font-size: 1em;
    width: 100%;
    margin-top: .75em;
    margin-bottom: .5em
}
.material table {
    width: 100%;
    display: table
}
.material .picker__calendar-container thead {
    border: none
}
.material .picker__table th, .material .picker__table td {
    text-align: center
}
.material .picker__weekday {
    font-size: .9rem
}
.material .picker__weekday {
    padding-bottom: .5em
}
.material .picker__weekday {
    padding-bottom: .25em;
    color: #999999;
    font-weight: 500
}
.material td, .material th {
    padding: 15px 5px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px
}
.material .picker__day--outfocus {
    display: none;
    padding: .75rem 0;
    color: #fff
}
.material .picker__day--infocus {
    color: #595959;
    letter-spacing: -.3;
    padding: .5rem 0;
    font-weight: 400;
    border: 1px solid transparent
}
.material .picker__table td {
    margin: 0;
    padding: 0
}
.material .picker__day.picker__day--today {
    color: #771a5a
}
.material .picker__day--infocus:hover {
    cursor: pointer;
    color: #000;
    font-weight: 500
}
.material .picker__day--selected, .material .picker__day--selected:hover, .material .picker--focused .picker__day--selected {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    background-color: #771a5a;
    color: #ffffff
}
.material .picker__footer {
    text-align: right;
    padding: 0 10px 5px 0
}
.material .picker__footer {
    text-align: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}
.material .btn-flat {
    box-shadow: none;
    background-color: transparent;
    color: #343434;
    cursor: pointer
}
.material .btn, .material .btn-large, .material .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    outline: 0;
    padding: 0 2rem;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent
}
.material .picker__close, .material .picker__today, .material .picker__clear {
    font-size: 1.1rem;
    padding: 0 1rem;
    color: #771a5a
}
.material .picker__clear {
    color: #343434
}
.material [type="checkbox"]:not(:checked), .material [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    visibility: hidden
}
.material input[type="checkbox"], .material input[type="radio"] {
    box-sizing: border-box;
    padding: 0
}
.material [type="checkbox"]+label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    min-height: 25px;
    line-height: 25px;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none
}
.material [type="checkbox"]:checked + label:before {
    background: #9a3eff;
    border-color: #9a3eff
}
.material [type="checkbox"]:checked+label:after {
    position: absolute;
    top: 1px;
    left: 6px;
    width: 16px;
    height: 16px;
    content: "x";
    color: #fff
}
.material [type="checkbox"]+label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    z-index: 0;
    border: 2px solid #5a5a5a;
    border-radius: 1px;
    margin-top: 2px;
    transition: .2s
}
.material [type="checkbox"]:not(:checked):disabled+label:before {
    border-color: #BDBDBD;
    background-color: rgba(0,0,0,0.26)
}
.material .dropdown-content li>span>label, .material .input-field.withinfo .dropdown-content li>span>label {
    top: 1px;
    left: 3px;
    height: 18px
}
.material input:not([type]).invalid+label:after, .material input:not([type]):focus.invalid+label:after, .material input[type=text].invalid+label:after, .material input[type=text]:focus.invalid+label:after, .material input[type=password].invalid+label:after, .material input[type=password]:focus.invalid+label:after, .material input[type=email].invalid+label:after, .material input[type=email]:focus.invalid+label:after, .material input[type=url].invalid+label:after, .material input[type=url]:focus.invalid+label:after, .material input[type=time].invalid+label:after, .material input[type=time]:focus.invalid+label:after, .material input[type=date].invalid+label:after, .material input[type=date]:focus.invalid+label:after, .material input[type=datetime-local].invalid+label:after, .material input[type=datetime-local]:focus.invalid+label:after, .material input[type=tel].invalid+label:after, .material input[type=tel]:focus.invalid+label:after, .material input[type=number].invalid+label:after, .material input[type=number]:focus.invalid+label:after, .material input[type=search].invalid+label:after, .material input[type=search]:focus.invalid+label:after, .material textarea.materialize-textarea.invalid+label:after, .material textarea.materialize-textarea:focus.invalid+label:after {
    content: attr(data-error);
    color: #F44336;
    opacity: 1
}
.material input:not([type])+label:after, .material input[type=text]+label:after, .material input[type=password]+label:after, .material input[type=email]+label:after, .material input[type=url]+label:after, .material input[type=time]+label:after, .material input[type=date]+label:after, .material input[type=datetime-local]+label:after, .material input[type=tel]+label:after, .material input[type=number]+label:after, .material input[type=search]+label:after, .material textarea.materialize-textarea+label:after {
    display: block;
    content: "";
    position: absolute;
    top: 60px;
    font-size: 11px;
    opacity: 0;
    transition: .2s opacity ease-out, .2s color ease-out
}
.material input:not([type]).valid, .material input:not([type]):focus.valid, .material input[type=text].valid, .material input[type=text]:focus.valid, .material input[type=password].valid, .material input[type=password]:focus.valid, .material input[type=email].valid, .material input[type=email]:focus.valid, .material input[type=url].valid, .material input[type=url]:focus.valid, .material input[type=time].valid, .material input[type=time]:focus.valid, .material input[type=date].valid, .material input[type=date]:focus.valid, .material input[type=datetime-local].valid, .material input[type=datetime-local]:focus.valid, .material input[type=tel].valid, .material input[type=tel]:focus.valid, .material input[type=number].valid, .material input[type=number]:focus.valid, .material input[type=search].valid, .material input[type=search]:focus.valid, .material textarea.materialize-textarea.valid, .material textarea.materialize-textarea:focus.valid {
    border-bottom: 1px solid #4CAF50;
    box-shadow: 0 1px 0 0 #4CAF50
}
.material input:not([type]).valid+label:after, .material input:not([type]):focus.valid+label:after, .material input[type=text].valid+label:after, .material input[type=text]:focus.valid+label:after, .material input[type=password].valid+label:after, .material input[type=password]:focus.valid+label:after, .material input[type=email].valid+label:after, .material input[type=email]:focus.valid+label:after, .material input[type=url].valid+label:after, .material input[type=url]:focus.valid+label:after, .material input[type=time].valid+label:after, .material input[type=time]:focus.valid+label:after, .material input[type=date].valid+label:after, .material input[type=date]:focus.valid+label:after, .material input[type=datetime-local].valid+label:after, .material input[type=datetime-local]:focus.valid+label:after, .material input[type=tel].valid+label:after, .material input[type=tel]:focus.valid+label:after, .material input[type=number].valid+label:after, .material input[type=number]:focus.valid+label:after, .material input[type=search].valid+label:after, .material input[type=search]:focus.valid+label:after, .material textarea.materialize-textarea.valid+label:after, .material textarea.materialize-textarea:focus.valid+label:after {
    content: attr(data-success);
    color: #4CAF50;
    opacity: 1
}
.bodycopy .withcreatelink a.button, .bodycopy .withcreatelink a.button:active, .bodycopy .withcreatelink a.button.blue, .bodycopy .withcreatelink a.button.blue:active {
    width: auto;
    font-size: 11px;
    line-height: 22px;
    height: 22px;
    padding: 0px 15px;
    display: inline-block;
    background: #9a3eff;
    top: 7px;
    right: 0.75rem;
    position: absolute
}
.bodycopy .withcreatelink a.button.blue, .bodycopy .withcreatelink a.button.blue:active {
    background: #08A8E6
}
.bodycopy .withcreatelink a.button.blue:hover {
    background: #10B3F3
}
.bodycopy .withcreatelink .addfield {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    right: 10px;
    top: 0;
    background: #fff;
    padding: 0;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    width: 320px;
    z-index: 999;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease
}
.bodycopy .withcreatelink .addfield.active {
    padding: 20px;
    max-height: 100px;
    opacity: 1
}
.bodycopy .withcreatelink .addfield a.button.no3d {
    top: 27px;
    right: 20px
}
.material .input-field.withinfo .addfield input[type="text"] {
    padding-left: 0;
    padding-right: 5em;
    margin: 0
}
.bodycopy .withcreatelink a.button.delete, .bodycopy .withcreatelink a.button.add {
    right: 30px;
    top: 27px
}
.bodycopy .withcreatelink a.button:hover {
    background: #751d59
}
.bodycopy .withcreatelink .select-wrapper svg.caret {
    right: 7em
}
.select-dropdown li.optgroup {
    border-top: 1px solid #eee
}
.material .select-dropdown li.disabled, .material .select-dropdown li.disabled>span, .material .select-dropdown li.optgroup {
    color: rgba(0,0,0,0.3);
    background-color: transparent
}
.material .select-dropdown li.optgroup>span {
    color: rgba(0,0,0,0.4)
}
.material .select-dropdown li.optgroup ~ li:not(.optgroup) {
    padding-left: 1rem
}
.material .file-field .btn, .material .file-field .btn-large {
    float: left;
    height: 3rem;
    line-height: 3rem
}
.material .file-field .btn, .material .file-field .btn-large {
    float: left;
    height: 3rem;
    line-height: 3rem;
    border-radius: 0;
    position: relative
}
.material .btn, .material .btn-large {
    text-decoration: none;
    color: #fff;
    background-color: #231545;
    text-align: center;
    transition: .2s ease-out;
    cursor: pointer
}
.material .btn, .material .btn-large, .material .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    outline: 0;
    padding: 0 2rem;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent
}
.material .btn:hover, .material .btn-large:hover {
    background: #751d59
}
.material .file-field span {
    cursor: pointer
}
.material .file-field input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0)
}
.material .file-field .file-path-wrapper {
    overflow: hidden;
    padding-left: 10px
}
.shadowbox {
    min-height: 85px;
    padding: 20px;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease
}
.shadowbox:hover {
    box-shadow: 0 0 10px rgba(0,0,0,0.5)
}
.material input[type="text"].file-path {
    margin: 0
}
.material .file-field .colour-lightgrey {
    padding: 20px 32% 20px 19%;
    margin: 20px -20px
}
.material .imagepreview {
    margin: 0;
    right: 20px;
    top: 20px;
    width: 26%;
    height: 80%;
    overflow: hidden;
    text-align: center
}
.material .imagepreview img {
    max-height: 100%;
    margin: 0 auto;
    display: inline-block
}
.minwidth {
    min-width: 120px;
    text-align: center
}
.material .shadowbox .shadowinner {
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
    opacity: 1;
    filter: alpha(opacity=1)
}
.material .shadowbox .shadowinner.hide {
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
    opacity: 0;
    filter: alpha(opacity=0);
    max-height: 0;
    overflow: hidden
}
.hiddendiv {
    visibility: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding: 50px
}
.judgingbox h3 {
    margin: 13px 0 0 0;
    font-size: 16px
}
.judgingbox .moredetails {
    padding: 11px 25px 11px 25px;
    display: inline-block;
    margin-right: 20px;
    color: #9a3eff;
    cursor: pointer;
    border: none;
}

.judgingbox .moredetails:after, .judgingbox .moredetails:before {
    content: "";
    width: 15px;
    height: 1px;
    background: #751d59;
    position: absolute;
    top: 20px;
    right: 0px;
    -webkit-transform-origin: center;
    -webkit-transform: rotate(135deg);
    -ms-transform-origin: center;
    -ms-transform: rotate(135deg);
    transform-origin: center;
    transform: rotate(135deg);
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}
.judgingbox .hide .moredetails:after, .judgingbox .hide .moredetails:before {
    background: #989797
}
.judgingbox .moredetails:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}
.judgingbox .shadowbox.hide .moredetails:before {
    content: "";
    transform: rotate(90deg)
}
.judgingbox .shadowbox.hide .moredetails:after {
    content: "";
    transform: rotate(0deg)
}

.judgingbox .moredetails:hover:before, .judgingbox .moredetails:hover:after {
    background: #751d59
}
.judgingbox .hide .moredetails:hover:before, .judgingbox .hide .moredetails:hover:after {
    background: #000
}
.judgingbox .shadowbox .shadowinner {
    -ms-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    opacity: 1;
   /* overflow: hidden;*/
    height: auto
}
.judgingbox.submissions .shadowbox .shadowinner {
    height: auto
}
.judgingbox .shadowbox.hide .shadowinner {
    overflow: hidden;
    opacity: 0;
    height: 0;
    -ms-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    transition: all 1s ease
}
.judgingbox .searchresults {
    border-top: 1px #EAEAEA solid;
    margin-top: 15px;
    padding-top: 20px
}
.judgingbox.bulkdownload .searchresults {
    border: none;
    margin: 0
}
.judgingbox.bulkdownload input[type="submit"] {
    margin-top: -80px
}
.judgingbox.bulkdownload .col {
    padding: 0
}
.material .judgingbox.bulkdownload .input-field.withinfo input {
    padding-left: 2.5em
}
.loginbox {
    top: 50%;
    left: 50%;
    width: 270px;
    height: 235px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 42px 20px 23px 20px;
    text-align: center;
    box-shadow: 0 10px 20px 10px rgba(0,0,0,0.4)
}
.loginbox h1 {
    text-align: center;
    margin: 0;
    font-size: 22px
}
.loginbox p {
    font-size: 16px;
    text-align: center
}
.hero.slideshow .featuredimage.active.login {
    background: url("../images/loginbg.jpg") repeat left top;
    -webkit-animation: tvs 100s infinite linear;
    -moz-animation: tvs 100s infinite linear;
    -o-animation: tvs 100s infinite linear;
    animation: tvs 100s infinite linear
}
@-webkit-keyframes tvs {
0% {
background-position:0 0
}
100% {
background-position:276% 0
}
}
@-moz-keyframes tvs {
0% {
background-position:0 0
}
100% {
background-position:276% 0
}
}
@-o-keyframes tvs {
0% {
background-position:0 0
}
100% {
background-position:276% 0
}
}
@keyframes tvs {
0% {
background-position:0 0
}
100% {
background-position:276% 0
}
}
.awarditem {
    margin-top: 30px
}
.smallheading {
    font-size: 16px;
    margin: 50px 0 15px 0
}
.button.small, .bodycopy .button.small {
    font-size: 11px;
    line-height: 22px;
    height: 22px;
    padding: 0px 15px;
    display: inline-block;
    background: #9a3eff
}
.button.small:hover, .bodycopy .button.small:hover {
    background: #751d59
}
.bodycopy input[type="submit"].anchor-style {
    padding: 0;
    background: none;
    box-shadow: none;
    color: #08A8E6;
    position: static
}
.video-container {
    width: 550px;
    margin: 0 auto
}
.search.filter-container {
    margin: 0;
    width: 190px
}
.search.filtercontainer {
    margin: 0;
    height: 70px;
    line-height: 70px
}
.search.filtercontainer select {
    font-weight: normal;
    line-height: normal;
    margin: 17px 0 0 20px;
    border: 1px #DEDEE0 solid;
    background-color: #fff;
    background-position: 95% 11px;
    width: 180px;
    padding: 8px 30px 8px 10px
}
.search.filtercontainer select:hover {
    border-color: #C1BEBE
}
.sort-text {
    margin: 0px 0px 0px 20px
}
.judgingbox h3 a {
    color: #2D2D2D;
    border: none
}
.judgingbox h3 a:hover {
    color: #7f0bff;
    border-bottom: 1px solid #DEDEE0
}


header {
    /*padding: 48px 0 9px 0;*/
    height:140px;
    text-align: right;
    position:relative;
}
header .button {
    margin-right: 15px
}
    header .logo {
        position:absolute;
        top:75px;
        left:0px;
        width:185px;
        z-index: 9999;
    }
        header .logo svg {
        max-width:100%;
        }
        nav {
    padding: 0px 25px 0 0px;
    position: absolute;
    top: 80px;
    left: 280px;
    width: 660px;
}
nav ul li a {
    text-align: left;
    color: #000;
    border: none;
    padding: 11px 10px;
    font-size:14px;
}
nav ul li a, nav ul li a span {
    -ms-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease
}
nav ul li a:hover, nav ul li.current a {
    /*background: #4f446a*/
    color:#7f0bff;
}
nav ul li {
    float: left;
    margin-right: 25px
}
nav .search, nav .account {
    position: relative;
    top: -16px;
    margin-right: 30px;
    width: 22px;
    height: 22px;
    background: url("../images/search.png") no-repeat center center;
    text-indent: -9999px;
    outline: none;
    display: inline-block;
    font-size: 0
}
nav .account {
    margin-right: 0;
    background: none;
	top: -6px
}
    nav .account svg {
        width: 22px;
        height: 22px;
        fill: #9A3EFF;
        display: block;
        transition: all 300ms cubic-bezier(1, 0, 0, 1)
    }
#useraccount.open svg {
    fill: #9a3eff
}

footer {
    line-height: 1.3em;
    padding: 40px 0;
    border-top: 1px #4c4367 solid;
    color: #737374;
    font-size: 0.9rem;
}
footer a {
    color: #bdb8c7;
    border: none
}
footer .right {
    text-align: right
}
.clearfix:before, .clearfix:after {
    content: " ";
    display: table
}
.clearfix:after {
    clear: both
}
.clearfix {
*zoom:1
}
.left {
    float: left
}
.right {
    float: right !important
}
.absolute {
    position: absolute;
    display: block
}
.relative {
    position: relative
}
.colour-primary {
    background: #231545
}
    .colour-primary.alpha {
        background: #9a3eff;
    }
.colour-secondary {
    background: #eaeaea
}
.colour-tertiary {
    background: #9a3eff
}
.colour-black {
    background: #000;
}
.nobg {
    background: none
}
.colour-lightgrey {
    background: #f7f6f7
}
.sprite, .views>div:before, .subnav li.preview a:after {
    background-image: url("../images/spritesheet.png");
    background-repeat: no-repeat
}
a {
    text-decoration: none
}
.container {
    margin: 0 auto;
    max-width: 940px
}
.button {
    position: relative;
    top: 0;
    -ms-transition: background 200ms ease;
    -webkit-transition: background 200ms ease;
    transition: background 200ms ease;
    color: #fec8ff;
    padding: 11px 35px 15px 35px;
    background: #9a3eff;
    box-shadow: inset 0 -4px 0 #751d59;
    border: none;
    border-radius: 0.1875rem;
}
.button {
    box-shadow: none;
    padding: 11px 25px 11px 25px;
	font-size: 14px
}
    .button:hover {
        background: #7f0bff;
    }
.button:active {
    box-shadow: none;
    padding: 11px 25px 11px 25px;
    top: 4px
}
#useraccount .dropme {
    transition: all 600ms cubic-bezier(1, 0, 0, 1);
    opacity: 0;
    max-height: 0;
    transform: translateY(20px);
    top: 160%;
    width: 255px;
    right: -14px;
    z-index: 10000;
    visibility: hidden
}
#useraccount.open .dropme {
    transform: translateY(0px);
    visibility: visible;
    max-height: 350px;
    opacity: 1
}
#useraccount ul {
    background: #ffffff;
    padding: 10px 0;
    box-shadow: 0 2px 5px 1px rgba(0,0,0,0.2)
}

#useraccount ul li {
    float: none;
    margin: 0
}
#useraccount ul li a, .dropdown-item ul li a {
    position: relative;
    color: #231744;
    padding: 14px 60px 14px 20px;
    display: block;
}
#useraccount ul li a, #useraccount ul li a strong, .dropdown-item ul li a {font-size: 14px}
#useraccount ul li a:before, .dropdown-item ul li a:before {
    content: "";
    width: calc(100% - 40px);
    height: 1px;
    background: #ececec;
    display: block;
    position: absolute;
    top: 0px;
	transition: all 0.2s ease
}
#useraccount ul li:first-of-type a:before, .dropdown-item ul li:first-of-type a:before{display: none}
#useraccount ul li a:hover:before,
#useraccount ul li:hover + li a:before,
.dropdown-item ul li a:hover:before,
.dropdown-item ul li:hover + li a:before
{background: transparent}

#useraccount ul li a:hover, .dropdown-item ul li a:hover{
    background-color: #f7f6f7;
    color: #9A3EFF;
}

#useraccount a span {
    background: #9a3eff;
    border-radius: 7px;
    display: block;
    line-height: 15px;
    position: absolute;
    width: 30px;
    height: 15px;
    right: 20px;
    font-size: 12px;
    top: 14px;
    text-align: center;
    color: #fec8ff;
}
#useraccount .account span {
    text-indent: 0;
    color: #fff;
    right: -10px;
    top: -6px
}
#useraccount ul li:last-child {
  
}
#useraccount ul li:last-child a {

}
    #useraccount ul li:last-child em {
        margin-top: 5px;
        display: block;
        text-transform: none;
        color: #9a3eff;
    }

#useraccount {right: 0; top: 5px}

.mobile-toggle {
	max-height: 500px;
	background: #3b255b;
	margin: 10px 0 -20px -20px;
    padding: 15px 20px 18px 20px;
    width: calc(100% + 40px);
}

.mobile-toggle.hidden {display: none}

#mobile-toggle-button {width: 30px; height: 22px; right: 50px; top: 33px}
#mobile-toggle-button span {position: absolute; width: 20px; display:block; height: 2px; background: #dedee0; left: 5px; top: 9px; transition: all 300ms cubic-bezier(1, 0, 0, 1)}

#mobile-toggle-button span:first-child {transform: translateY(-7px)}
#mobile-toggle-button span:last-child {transform: translateY(7px)}

#mobile-toggle-button.opened span {opacity: 0; background: #018FCF}

#mobile-toggle-button.opened span:first-child {opacity:1; transform: translateY(0px) rotate(45deg)}
#mobile-toggle-button.opened span:last-child {opacity:1; transform: translateY(0px) rotate(-45deg)}


.subnav-toggle, .mobile-td-toggle {
	display: none;
	position: absolute;
    right: 0;
    top: 7px;
	z-index: 1;
	cursor: pointer;
	color: #231545;
    text-transform: uppercase;
	padding: 20px 0 20px 30px;
	transition: all 0.3s ease
}
.subnav-toggle:before, .subnav-toggle:after, .mobile-td-toggle:before, .mobile-td-toggle:after {
	content:"";
	position: absolute;
	top: 25px;
	left: 7px;
	width: 15px;
	height: 1px;
	background: #989797;
	transition: all 0.3s ease
}
.subnav.open .subnav-toggle:before, .subnav.open .subnav-toggle:after, .mobile-td-toggle.opened:before, .mobile-td-toggle.opened:after {background: #231545}

.mobile-td-toggle:before, .mobile-td-toggle:after {top: 14px}
.mobile-td-toggle {
right: 20px;
    top: 25px;
    width: 30px;
    height: 30px;
    padding: 0;
    transform-origin: center
}

.mobile-td-toggle.opened {transform: rotate(45deg)}

.subnav-toggle:after, .mobile-td-toggle:after  {transform: rotate(90deg)}

.search-actions > span:first-of-type {margin-right: 15px}

.score-panel {
width: auto!important;
    float: none;
    clear: both;
	position: relative
}


.shadowbox .score-panel {
    margin: 0 -20px!important; padding: 0 30px
}

.score-panel .section-padding{padding: 20px}

.score-panel p {color: #fff; font-size: 16px}
.video-container {width: 60%}

.icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.score-toggle-box .container .inner {display: none}
.score-toggle-box .container:before {
    position: absolute;
    top: -14px;
    width: 0;
    height: 0;
    right: 70px;
    border-style: solid;
    border-width: 0 11px 14px 11px;
    border-color: transparent transparent #221641 transparent;
    content: "";
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s ease
}
.score-toggle-box.opened .container:before {opacity: 1}
.score-toggle-box .heading {font-size: 20px; margin: 10px 0 30px 0}
.score-toggle-box .inner > div {transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); padding: 50px 0; transform:  scale(0.8);  opacity: 0}
.score-toggle-box .inner > div + div {padding-top: 0}
.score-toggle-box.opened .inner > div {transform:  scale(1); opacity: 1}
.score-toggle-box .inner {font-size: 16px}
table thead strong {text-transform: none}
.white-text {color: #fff}

.score-toggle-box .ratings {margin: 0 -5px; display: flex; flex-direction: row; flex-wrap: wrap; max-width: 400px}
.score-toggle-box .ratings input[type="radio"] {display: none}
.score-toggle-box .ratings label {cursor: pointer;  display: inline-block; position: relative; z-index: 10;  font-size: 21px!important; width: 60px; height: 60px; text-align: center; line-height: 60px; margin: 8px}
.score-toggle-box .ratings label:before {content:""; transition: all 0.2s ease-in-out; width: 100%; height: 100%; position: absolute; top: 0; left: 0; background: #7f0bff; z-index: -1; transform: scale(0); border-radius: 50%; opacity: 0}
.score-toggle-box .ratings label:after {content:""; transition: all 0.2s ease-in-out; width: 100%; height: 100%; position: absolute; top: -2px; left: -2px; z-index: -2; border-radius: 50%; border: 2px #fff solid}
.score-toggle-box .ratings label:hover:after {border-color: #7f0bff}

.score-toggle-box .ratings input[type="radio"]:disabled + label, .score-toggle-box .comment textarea:disabled {cursor: not-allowed}

.score-toggle-box .ratings input[type="radio"]:disabled + label:after,
.score-toggle-box .ratings input[type="radio"]:disabled + label:hover:after {border-color: #49396f}


.score-toggle-box .ratings input[type="radio"]:disabled:not(:checked) + label {display: none} 

.score-toggle-box .comment textarea:disabled  {
    background: #231545;
    border: 2px #312256 solid
}

.score-toggle-box p {margin: 20px 0}
.score-toggle-box .ratings input[type="radio"]:checked + label:before {transform: scale(1.1); opacity: 1} 
.score-toggle-box .ratings a {color: #018FCF; border: none}
.score-toggle-box .comment textarea {background: #382a5f; width: 100%; display: block; border: none; outline: none;  min-height: 150px; padding: 20px; line-height: 1.5; resize: none; transition: all 0.2s ease-in-out}

.score-toggle-box .comment textarea:focus {background: #493a73}

.shortlist {position: absolute;right: 20px;bottom: 0}

.shortlist .input-field {margin: 0}
.shortlist.material [type="checkbox"]+label {left: 0; margin: 0 0 30px 0}

.resultblock.shortlisted {}
    .resultblock.shortlisted .details h3:after {
        display: inline-block;
        content: "Shortlisted";
        margin-left: 10px;
        color: #ffffff;
        background: #9a3eff;
        font-size: 13px;
        padding: 4px 12px;
        position: relative;
        top: -2px;
    }
    .resultblock.shortlisted .infopanel {
        background: #9a3eff
    }
.resultblock.shortlisted .icons svg {color: #fff}
.resultblock.shortlisted .score span {background: #fff; color: #731d57}


.resultblock.shortlisted .infopanel.iconblock .score a:not(.button), .resultblock.shortlisted .infopanel.iconblock .icons > div > a:not(.button) {color: #fff}
/*.resultblock.shortlisted .infopanel.iconblock a:not(.button):hover,
.resultblock.shortlisted .infopanel.iconblock a:not(.button):focus,
.resultblock.shortlisted .infopanel.iconblock .score a.opened
{color:#7f0bff}*/

.resultblock.shortlisted .icons .dropdown > a:after {border-color: #fff}

.topline {border-top: 1px #382a5f solid}



.table-outer-padding {padding: 25px; background: #fff}

table{
    width: 100%;
    display: table;
	border-collapse: collapse;
    border-spacing: 0;
	color: #000;
	line-height: 1.5;
	table-layout: fixed;
	font-size: 16px
}

table, th, td {
    border: none;
}
.mindshare-table thead, .mindshare-table tbody tr {
    border-bottom: 1px solid #eaeaea;
}
.mindshare-table tbody tr:last-child {border-bottom: none}
td, th {
    padding: 17px 15px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
}
.flex {display: flex}
.score.flex {align-items: center}
.score-tag {	
	width: 37px;
    height: 37px;
    background: #eaeaea;
    display: inline-block;
    line-height: 37px;
    border-radius: 50%;
    text-align: center;
	transition: all 0.2s ease
}
.score a {border: none}
.score-tag + span {margin-left: 10px}

.case-row .score-toggle-box .container:before {right: 22px}

.button-block {margin: 30px 0 0 0}

.button-block .button + .button {margin-right: 20px}

.table-case-study-title .relative{display: inline-block; display: flex; font-size: 14px; border: none; align-items: center}
.table-case-study-title .relative figure {width: 40px; height: 40px; position: relative; left: 0; top: 0; margin: 0 15px 0 0}

.table-case-study-title.score-box {
    display: flex;
    justify-content: space-between;
    padding: 17px 0;
	border-top: 1px solid #eaeaea;
	position: relative
}
.all-score-items .score-tag {
    background: #7f0bff;
    color: #fff;
}
.all-scores {margin-top: 15px}
.all-score-items div {padding: 10px 10px 10px 0}


.table-case-study-title.score-box:before {transition: all 0.2s ease; content: ""; position: absolute; background: #f2f2f2; height: calc(100% + 2px); width: calc(100% + 40px); left: -20px; top: -1px; opacity: 0}
.table-case-study-title.score-box:hover:before, .table-case-study-title.score-box.opened:before {opacity: 1}

@supports (object-fit: cover) {
		.casestudieswrap .box .imgwrap img, .resultblock .figure img, .table-case-study-title .relative figure img {height: 100%; width: 100%;object-fit: cover}
}
/* CSS specific to iOS devices to dismiss toggle items when clicking body */
@supports (-webkit-overflow-scrolling: touch) {  body{ cursor:pointer; } * {-webkit-tap-highlight-color: rgba(0,0,0,0)} }


@media (max-width: 1024px) {
	
}

@media (max-width: 1023px) {
	.container {padding-right: 20px; padding-left: 20px}
	.shadowbox .score-panel {padding: 0}
	header {height:100px}
        header .logo {top:45px;width:160px; }
            #useraccount {top: 37px}
    nav {
        padding: 75px 0 0 0;
        top: 15px;
        left: 0px;
        right: 0px;
        width: auto;
        z-index: 99;
        
    }
	.mobile-toggle li {display: block; float: none; margin: 0}
    .mobile-toggle ul li a {
        display: block;
        text-align: center;
        color: #fec8ff;
    }
    .mobile-toggle {
        text-align: center;
        font-size: 15px;background: #9a3eff;
    }
    header .button {
        margin: 10px 0;
        display: block;
        background: #fec8ff;
        color:#9a3eff;
    }
	.footernews a {width: calc(50% - 30px)}
	
	.squareblock, .searchresults.grid .resultblock {width: calc(50% - 26px)}
	.infopanel.iconblock > * {display: block}
	
	.infopanel.case-study-page.iconblock {display: block}
	.infopanel.case-study-page  .icons + div {margin-top: 20px}
	.score a span {margin-left: 7px}
	.shortlist {position: static}
	
	.case-study-page + .score-toggle-box .container:before {left: 35px}
	
	.case-row .score-toggle-box .container:before {
    right: 54px;
}
}
@media (max-width: 768px) {
	.search h2, .pageheading h2, .pageheading h1 {float:none!important; margin: 0}
	.material .row .col.m4 {width: 100%; margin-bottom: 20px}
	
	.resultblock>div, .searchresults.list .resultblock>div:first-child {width: 100%; max-width: none; margin-right: 0}
	.resultblock.shortlisted .details h3:after {margin: 10px 0; display: block; width: 100px; text-align: center}
	.score-toggle-box .container:before {right: 106px}
	
		.subnav.open .subnav-toggle {color: #7f0bff}
	.subnav.open .subnav-toggle:before {transform: rotate(45deg)}
	.subnav.open .subnav-toggle:after {transform: rotate(135deg)}
	.subnav-toggle {display: block}
	.subnav li:not(.active) {max-height: 0; overflow: hidden; border: none}
	.subnav.open li:not(.active), .subnav.open + .subnav li:not(.active) {max-height: 100px;}
	.subnav li.preview a {text-align: right}
	.subnav.right {float: none!important}
	
		.subnav {float: none; width: 100%}
	.subnav li {display: block}
	.subnav li a {padding: 25px 0}
	.subnav.numbered li a:before {left: 0}
	.subnav.numbered li a {padding: 26px 14px 25px 30px}
	
}
@media (max-width: 767px) {
	.mobile-td-toggle {display: block}
	.score-toggle-box .container:before {left: 35px; right: auto!important}
			.infopanel:not(.case-study-page).iconblock {margin: 0 -20px; display: block}
	.search input[type="text"] {max-width: 62%; margin-top: 0}
	.search .container>input[type="submit"] {width: 38%;margin-left: -4px}
	#advancedtoggle {float: right;margin-bottom: 20px}
	
	article.newsarticle, aside.morenews {width: 100%; padding-right: 0 ;min-height: 0}
	
	.squareblock, .searchresults.grid .resultblock {width: calc(100% - 26px)}
	.infopanel .threecol {width: 100%; padding: 0}
	.search .checkbox label {margin-bottom: 10px}
	.search .container .form:last-child {padding: 20px 0 25px 0}
	#advancedsearch>.container {transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);}
	#advancedsearch{ transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1)}
	#advancedsearch.open {max-height: 750px}
	.squareblock {width: calc(100% - 26px)}
	.material .picker__frame {top: 5%}
	.mindshare img {max-width: 150px}
	/*nav {padding: 69px 0 0 0}*/
	/*#mobile-toggle-button {top: 24px}
	#useraccount {top: 28px}*/
	.footernews a {width: calc(100% - 30px)}
	.morecasestudies.homeversion .casestudieswrap>div.slider {width: calc(100% - 0px); height: 330px}
	.morecasestudies.homeversion .casestudieswrap .slider.left .slide {height: 330px}
	.morecasestudies.homeversion .casestudieswrap>div {width: calc(100% - 0px);height: 214px}
	.casestudieswrap>div {margin: 26px 0 0 0}
	.morecasestudies.homeversion .casestudieswrap>div div.splitbox {height: 216px;width: calc(100% - 0px)}
	.morecasestudies.homeversion .casestudieswrap>div:last-child {height: auto!important}
	.morecasestudies.homeversion .casestudieswrap>div div.splitbox.right {margin-top: 26px}
	footer .right {float:left!important; margin-top: 26px; text-align: left}
	
	.author .imageblock.homeversion {display: none}
	.slideshow .author .headings {width: 100%;padding: 0 15px 0px 15px}
	.slideshow .author h1, .slideshow .author .title, .slideshow .author .headings h4 {font-size: 17px}
	.hero.slideshow .author {
	width: 48%;
	-webkit-transform: translateY(-93px);
    -ms-transform: translateY(-93px);
    -o-transform: translateY(-93px);
    transform: translateY(-93px)
}
	.hero.slideshow .author:nth-child(2) {left: auto; right: 0}
	.hero.slideshow .author:nth-child(3) {display: none}
	
	.author .authorinfo p {display: none}
	.hero:not(.slideshow) {height: auto!important; min-height: 60vh!important; padding-top: 60vh; background-size: auto 60vh;}
	.hero.slideshow, .bxslider.homeheader, .hero.slideshow .container, .hero.slideshow>ul>li, .bxslider>li {height: 75vh!important; min-height: 0; padding-top: 0}
	section.hero:not(.slideshow) .author {
	width: calc(100% + 40px);
    max-width: none;
    position: static;
    margin: 0 -20px;
	background: rgba(35,21,69,1);
	}
	.container.infopanel:before {opacity: 1}
	.playbutton {top: 30vh}
    .twocol-parent > div {flex: 1 1 100%}
	
	.morecasestudies:not(.homeversion) .casestudieswrap>div.slider {width: 100%; height: 500px}
	.morecasestudies:not(.homeversion) .casestudieswrap .box .author {max-width: none}
	.morecasestudies:not(.homeversion) .casestudieswrap>div.slider .box .author {background: rgba(35,21,69,1); min-height: 44%}
	.morecasestudies:not(.homeversion) .casestudieswrap>div.slider .box .imgwrap {height: 56%}
	.morecasestudies:not(.homeversion) .casestudieswrap .bxslider>li, .morecasestudies:not(.homeversion) .casestudieswrap .slider.left .slide, .morecasestudies:not(.homeversion) .casestudieswrap .bxslider {height: 500px!important}
	.morecasestudies:not(.homeversion) .casestudieswrap .bx-prev, .casestudieswrap .bx-next {top: 28%}
	.morecasestudies:not(.homeversion) .casestudieswrap>div {width: 100%; margin-left:0 ;margin-right: 0}
	.slideshow.relative {margin: 20px -20px}
	.slideshow.relative, .slideshow.relative li {height: 350px!important}
	.info .infotext, .search-bulk span, .infopanel .bulk span {width: 200px!important}

	.article-container {display: flex; flex-direction: column}
	aside.morenews {order: 2}
	
	.searchtoggle {height: auto}
	.search-actions, .searchtoggle .subheading  {float: none!important}
	.search-actions > span {display: block}
	.searchresults.list .resultblock .details {padding-left: 0}
	.material .file-field .colour-lightgrey {padding: 20px}
	.material .imagepreview {position: static;width: 100%;height: auto}
	.video-container {width: 100%}
	.video-container video {width: 100%;height: auto}
	
	.judgingbox h3 {float: none}
	.judgingbox .moredetails {
	float: none!important;
    display: block;
    padding: 10px 20px 10px 0px;
    margin: 0
	}
	
	.infopanel.iconblock > * {display: block}
	.icons > div {display: block;margin: 0;}
	.icons > div > a {margin: 10px 0}
	.score-box {margin-top: 0px}
	
	.score-toggle-box .ratings label {width: 40px; height: 40px; line-height: 40px}
	.score-toggle-box .inner > div {
    padding: 30px 0;
}
	.icons .dropdown .dropdown-item {width: 100%}
	.resultblock>div.score-box {margin: 0}
	.table-outer-padding {padding: 0}
	
tr.active {
    background: #f2f2f2;
}

	table.mobile-toggle-rows, .mobile-toggle-rows thead, .mobile-toggle-rows tbody, .mobile-toggle-rows th, .mobile-toggle-rows td, .mobile-toggle-rows tr {
    display: block;
}
	.mobile-toggle-rows thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
}
	.score-tag {background: #7f0bff; color: #fff}
	.mobile-toggle-rows td {position: relative}
	.mobile-toggle-rows td:before {display: block; text-transform: none}
	.score-table td:nth-of-type(1):before {
    content: "Name";
}
	.score-table td:nth-of-type(2):before {
    content: "Country";
}
	.score-table td:nth-of-type(3):before {
    content: "Score";
}
	.score-table td:nth-of-type(4):before {
    content: "Comment";
}
	.judge-activity-table td:nth-of-type(1):before {
    content: "Name";
}
	.judge-activity-table td:nth-of-type(2):before {
    content: "Times Scored";
}
	.judge-activity-table td:nth-of-type(3):before {
    content: "Last Case Study Scored";
}
	.judge-activity-table td:nth-of-type(4):before {
    content: "Score";
}
	
	.mobile-toggle-rows td:nth-of-type(2), .mobile-toggle-rows td:nth-of-type(3), .mobile-toggle-rows td:nth-of-type(4) {display: none}
	.mobile-toggle-rows tr.opened td {display: block!important}
	.mobile-toggle-rows tr.opened {background-color: #f2f2f2}
	
	.mindshare-table thead {border-bottom: none}
	
	.button-block * {margin-left: 0!important; margin-right: 0!important; width: 100%}
	.table-case-study-title.score-box {display: block}
	.table-case-study-title .relative {align-items: flex-start}
	.table-case-study-title.score-box .score {margin: -20px 0 0 55px; display: block;position: relative;}
	
	.infopanel.iconblock a.button {
		float: none!important;
    	display: inline-block;
    	margin: 0 0 20px 0;
	}
}

@media (min-width: 768px) {
.table-comment {width: 45%}
.table-last-case-study {width: 40%}
table>tbody>tr {transition: background-color 0.2s ease}

table>tbody>tr:hover {
    background-color: #f2f2f2;
}
	table>tbody>tr:hover .score-tag {background: #7f0bff; color: #fff}
.all-score-items {display: flex; flex-direction: row; flex-wrap: wrap}
.all-score-items div {width: 25%; padding: 10px 10px 10px 0}
}
@media (min-width: 1024px) {
	.mobile-toggle {display:block!important; visibility: visible;  margin: 0; background: none; width: auto; padding: 0}
	#mobile-toggle-button {display: none}
}

.details strong {
    font-weight: bold;
}

.news .container.bodycopy {
    background: #f7f6f7;
}

.error{
    color:red;
}

.video-overlay video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.slider img{
    width: 100%;
    height: 600px;
    background-size: cover;
    object-fit: cover;
    object-position: top;
}

.status-page{
    height: 500px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom right;
}

.slider{
    padding-bottom: 25px;
}

nav ul{
    list-style: none;
}
.login ul{
    list-style-type: disc;
}

.login ul li{
    margin-left: 30px;
}

.react-select-container .react-select__control{
    border-radius: 0px;
    margin-bottom: 20px;
}

.react-select-container .react-select__input-container .react-select__input{
    height: inherit;
}
.swiper-pagination {
    position: relative !important;
    margin-top: 40px !important;
}

.searchresults {
    min-height: 300px;
}
.home.hero:not(.hero-featured) {
    padding-top: 0px;
}

.bodycopy p {
    font-size: 1rem;
    white-space: break-spaces;
}

.dropdown-list{
    z-index: 1000;
}

.materialize-textarea{
    padding-bottom: 25px !important;
}

.__cropro_ a[href^="https://markerjs.com"]{
    visibility: hidden;
}

.__cropro_ [title="Aspect ratio"],
.__cropro_ [title="Zoom to selection"],
.__cropro_ [title="Straighten"]{
    display: none;
}

.hero{
    background-size: cover, 250px 250px;
    background-position: center top, center center;
}